import React from 'react';

function removeImageAndVideoInMarkdown(blog) {
  const contentfulMarkdownRegexForImageAndVideo = /(?:!\[(.*?)\]\((.*?)\))|(`(.*)`)/g;

  return blog.childMarkdownRemark.rawMarkdownBody.replace(contentfulMarkdownRegexForImageAndVideo, '').substring(0, 100)
}

export default function SingleBlog(props) {
  const { blogUrl, slug, previewPhoto, title, blog, author, createdAt, dateCreated } = props;
  const singleBlogPostUrl = blogUrl + slug;

  return (
    <div className="col-md-4 mb-5">
      <a href={singleBlogPostUrl} className="article-link">
        <div className="card blog-card">
          <img src={previewPhoto} alt="Blog post preview photo" />
          <div className="p-5">
            <h4>{title}</h4>
            <p className="gray mb-5">
              {removeImageAndVideoInMarkdown(blog)}
              ...
            </p>
            <div className="d-flex justify-content-between mta">
              <span className="purple">{author.name}</span>
              <span className="dark-gray">
                {dateCreated || new Date(createdAt).toDateString()}
              </span>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import HelmetComponent from '../components/helmetcomponent';
import defaultImg from '../img/defaultImg.jpg';
import SingleBlog from './single-blog';
const blogUrl = require('../constants').BLOG_URL;

function Blogs(params) {
  const { blogPosts, featuredBlogPost } = params

  return blogPosts?.map((post) => {
    const { slug, blogPreviewPhoto, title, blog, author, createdAt, dateCreated, pdfLink } = post.node;
    const isBlogSlugSameAsFeaturedBlogPostSlug = slug === featuredBlogPost.slug;

    if (!isBlogSlugSameAsFeaturedBlogPostSlug && !pdfLink) {
      let previewPhoto = defaultImg;

      if (blogPreviewPhoto) {
        previewPhoto = blogPreviewPhoto.file.url
      }

      return (
        <SingleBlog
          author={author}
          blog={blog}
          blogUrl={blogUrl}
          createdAt={createdAt}
          dateCreated={dateCreated}
          previewPhoto={previewPhoto}
          slug={slug}
          title={title}
        />
      )
    }
  })
}

function setFeaturedPreviewPhoto(featuredBlogPost) {
  return featuredBlogPost.blogPreviewPhoto 
    ? featuredBlogPost.blogPreviewPhoto.file.url
    : defaultImg;
}

export default function Template({ data }) {
  const blogPostsToShow = 6;
  const regex = /(?:!\[(.*?)\]\((.*?)\))|(`(.*)`)/g; //contentful markdown image and video regex
  const [showBlogPosts, setShowBlogPosts] = useState(blogPostsToShow);

  const onLoadMore = e => {
    e.preventDefault();
    setShowBlogPosts(showBlogPosts + blogPostsToShow);
  };

  const { title, description, featuredBlogPost } = data.allContentfulPageBlog.edges[0].node;
  const blogs = data.allContentfulBlogPost;
  const showLoadMore = blogs?.edges.length > showBlogPosts;

  const featuredPreviewPhoto = setFeaturedPreviewPhoto(featuredBlogPost);

  return (
    <Layout nav="colored-nav">
      <HelmetComponent title={title} description={description} />
      <div>
        <section id="section-blog">
          <div id="page-sub-header" className="white-space">
            <div className="container">
              <div className="page-sub-header-content">
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <h1 className="black">{title}</h1>
                    <p className="gray">
                      {description.description}
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="featured-article" className="mt40">
          <div className="container">
            <div className="card blog-card">
              <a href={blogUrl + featuredBlogPost.slug} className="article-link">
                <div className="row">
                  <div className="col-md-8">
                    <img src={featuredPreviewPhoto} alt="" className="w-100" />
                  </div>
                  <div className="col-md-4 p-3">
                    <h2 className="f36">{featuredBlogPost.title}</h2>
                    <p className="f24">
                      {featuredBlogPost.blog.childMarkdownRemark.rawMarkdownBody
                        .replace(regex, '')
                        .substring(0, 100)}
                      ...
                    </p>
                    <div className="blog-details">
                      <div className="d-flex justify-content-between pr-5">
                        <span className="purple">{featuredBlogPost.author.name}</span>
                        <span className="dark-gray">
                          {new Date(featuredBlogPost.createdAt).toDateString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>

        <section id="articles" className="mt-5">
          <div className="container">
            <div className="row blog-box">
              <Blogs blogPosts={blogs.edges} featuredBlogPost={featuredBlogPost}/>
            </div>
          </div>
          {showLoadMore && (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div id="loadMore">
                    <a href="#" onClick={onLoadMore}>
                      Load More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    site {
      siteMetadata {
        disqus
      }
    }

    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        date(formatString: "MMMM DD, YYYY")
        title
        description
        attachments {
          filename
        }
        related {
          post
        }
      }
    }

    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            path
          }
        }
      }
    }

    allContentfulPageBlog {
      edges {
        node {
          title
          description {
            description
          }
          featuredBlogPost {
            slug
            title
            createdAt
            blog {
              blog
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            author {
              name
            }
            blogPreviewPhoto {
              file {
                url
              }
            }
          }
        }
      }
    }
    allContentfulBlogPost(sort: { fields: dateCreated, order: DESC }, filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          dateCreated(formatString: "MMMM DD, YYYY")
          slug
          author {
            name
          }
          createdAt
          title
          blog {
            blog
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          blogPreviewPhoto {
            file {
              url
            }
          }
          pdfLink
        }
      }
    }
  }
`;
